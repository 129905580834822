<template>
  <div class="w-full p-4 overflow-auto border-t border-gray-300 tests pb-80">


    <div class="flex items-center space-x-4">
      <p-checkbox
        v-model="disabled"
        class="mt-4"
        label="Disabled"
      />
      <p-input
        v-model="label"
        class="w-32 mt-4"
        placeholder="Label"
      />
      <p-input
        v-model="error"
        class="w-32 mt-4"
        placeholder="Error message"
      />
    </div>

    <hr class="my-4" />

    <p-date-picker
      v-model="date"
      :disabled="disabled"
      :error="error"
      :label="label"
      class="w-40"
    />


    <div class="flex flex-col mt-4 space-y-4">

      <PFile
        :allowPaste="true"
        :disabled="disabled"
        :error="error"
        class="w-96"
        label="File"
        @change="onFile"
      />

      <PSelect
        v-model="selectedItem"
        :clearable="true"
        :disabled="disabled"
        :error="error"
        :fixedHeight="false"
        :items="items"
        :searchable="true"
        class="w-96"
      >
        <template v-slot:caption="{ item }">
          <div v-if="item">
            <div class="text-lg">{{ item.text }}</div>
            <div class="text-sm">{{ item.someprop }}</div>
          </div>
        </template>
        <template v-slot="{ item }">
          text: {{ item.text }}
        </template>
      </PSelect>

      <PSelect
        v-model="selectedItem"
        :disabled="disabled"
        :error="error"
        :items="items"
        :searchable="true"
        class="w-96"
      />

      <div class="w-96">
        <p-post-code-picker />
      </div>

      <p-location-picker
        v-model="location"
        :corporate="true"
        :disabled="disabled"
        :error="error"
        :pooling="true"
        class="w-1/3"
      />

      <div>{{ location }}</div>

      <p-status-picker
        v-model="statuses"
        :disabled="disabled"
        :error="error"
        show-statuses="0,1,2,3,4,5,6"
      />

      {{ statuses }}

      <p-button
        :disabled="disabled"
        @click="onClick"
      >Primary</p-button>

      <p-button
        :disabled="disabled"
        color="secondary"
      >Secondary</p-button>

      <p-button
        :disabled="disabled"
        color="danger"
      >Danger</p-button>

      <p-input
        v-model="input"
        :disabled="disabled"
        :error="error"
        :label="label"
        :wait="500"
        placeholder="Placeholder"
      /> {{
        input }} (debounce:500)

      <p-number
        v-model="number"
        :disabled="disabled"
        :error="error"
        :label="label"
        class="w-20"
      />

      <p-number
        :disabled="disabled"
        :error="error"
        :max="200"
        :min="0"
        :step="17"
        :value="numberSteps"
        :wait="500"
        class="w-20"
        label="Step 17 and wait 500"
        @input="updateQuantity('x', $event)"
      /> {{ numberSteps }}

      <p-textarea
        v-model="textarea"
        :disabled="disabled"
        :error="error"
        :label="label"
      ></p-textarea> {{ textarea }}

      <p-option-group
        v-model="item"
        :items="items"
      ></p-option-group>

      <p-checkbox
        v-model="check"
        :disabled="disabled"
        :label="label"
      />

      <p-toggle
        v-model="toggle"
        :disabled="disabled"
        :label="label"
      />

      <div class="flex mt-4 space-x-6">
        <button
          v-for="n in 5"
          :key="n"
          :class="{ 'border-green-500 text-green-500': tab === n }"
          class="py-2 border-b-2 border-transparent select-none hover:border-gray-300 hover:text-gray-800 focus:outline-none focus:border-green-500 focus:text-green-500"
          @click="tab = n"
        >
          Tab {{ n }}
        </button>
      </div>


      <!-- <p-product-picker :disabled="disabled" v-model="product" :label="label" :error="error"></p-product-picker> {{ product }} -->

    </div>

  </div>
</template>

<script>

import PButton from "@/components/forms/PButton"
import PInput from "@/components/forms/PInput"
import PTextarea from "@/components/forms/PTextarea"
import POptionGroup from "@/components/forms/POptionGroup"
import PCheckbox from "@/components/forms/PCheckbox"
import PToggle from "@/components/forms/PToggle"
import PNumber from "@/components/forms/PNumber"
import PDatePicker from "@/components/forms/PDatePicker"
// import PProductPicker from "@/components/forms/PProductPicker"
import PPostCodePicker from "@/components/forms/PPostCodePicker"

export default {
  name: "test",
  components: {
    PButton,
    PInput,
    PTextarea,
    POptionGroup,
    PCheckbox,
    PToggle,
    PNumber,
    PDatePicker,
    // PProductPicker,
    PPostCodePicker
  },
  data() {
    return {
      label: "Label",
      error: "",
      input: "",
      items: [
        { value: 0, text: "Option 0", someprop: "Hello" },
        { value: 1, text: "Option 1", someprop: "Hello" },
        { value: 2, text: "Option 2", someprop: "Hello" },
        { value: 3, text: "Option 3", someprop: "Hello" },
        { value: 4, text: "Option 4", someprop: "Hello" },
        { value: 5, text: "Option 5", someprop: "Hello" },
        { value: 6, text: "Option 6", someprop: "Hello" },
        { value: 7, text: "Option 7", someprop: "Hello" },
        { value: 8, text: "Option 8", someprop: "Hello" },
        { value: 9, text: "Option 9", someprop: "Hello" },
      ],
      item: 2,
      selectedItem: 2,
      textarea: "",
      radio: 1,
      number: 0,
      numberSteps: 0,
      check: false,
      toggle: false,
      disabled: false,
      date: "2020-08-30",
      product: null,
      tab: 1,
      statuses: "2,3",
      location: "",
    }
  },
  methods: {
    onClick() {
      console.debug('Controls, on click')
    },
    onFile(files) {
      console.debug('Controls, on files:', files)
    },
    updateQuantity(s, quantity) {
      console.debug(s, quantity)
    }
  },
  // watch: {
  //   numberSteps: function (val) {
  //     console.debug("controls value:", val)
  //   }
  // }
}
</script>
